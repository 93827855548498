import React from 'react'

export function GoogleSearch(props) {
  if(!props?.code){
    return null
  }
  return (
    <div>
    <script
async
src={`https://cse.google.com/cse.js?cx=${props.code}`}
></script>
<div className="gcse-search"></div>
  </div>
  )
}


